<template>
  <calculator />
</template>

<script>
import Calculator from '@/views/investor/components/Calculator.vue'

export default {
  name: 'CalculatorPage',
  components: {
    Calculator,
  },
}
</script>
